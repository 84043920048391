import axiosInstance from "./axiosInstance.js";

export const getCtfList = (params) => {
    return axiosInstance({
        url: '/CTF/getCtfList',
        method: 'get',
        params

    })
}
export const getUUIDRankList = (uuid) => {
    return axiosInstance({
      url: '/rank/getUUIDRanksList?uuid='+uuid,
      method: 'get',

    })
  }

  export const getUUIDCtf = (uuid) => {
    return axiosInstance({
        url: '/CTF/findUUIDCtf?uuid='+uuid,
        method: 'get',

    })
}