<template>
  <div class="auth">
    <div class="auth-container">
      <div class="heading">个人信息</div>
      <ErrorBox v-if="msg" :msg="msg" :icon="icon" />
    </div>
    <div class="auth-subheading"></div>
    <div class="form-div">
      <div class="form">
        <div class="info">
          <input v-model="userInfo.nickName" type="username" class="adminFormTitle inputField" id="nickname"
            name="nick_name" placeholder="昵称" required="true" />
        </div>
        <div class="info">
          <input v-model="userInfo.group_name" type="username" class="adminFormTitle inputField" id="groupname"
            name="group_name" placeholder="单位" required="true" />
        </div>
        <div class="button-class">
          <el-button type="primary" @click="reset">确定</el-button>
          <el-button type="warning" @click="dialogFormVisible = true">修改密码</el-button>
        </div>

      </div>
      <img src="@/assets/login.svg" class="auth-image" />
      
    </div>


    <el-dialog title="修改密码" :visible.sync="dialogFormVisible">
      <el-form :model="passWordForm">
        <!-- Old Password -->
        <el-form-item label="旧密码" :label-width="formLabelWidth">
          <el-input v-model="passWordForm.oldPassword" type="password" autocomplete="off">
          </el-input>
        </el-form-item>

        <!-- New Password -->
        <el-form-item label="新密码" :label-width="formLabelWidth">
          <el-input v-model="passWordForm.newPassword" type="password" autocomplete="off">
          </el-input>
        </el-form-item>

        <!-- Confirm New Password -->
        <el-form-item label="确认新密码" :label-width="formLabelWidth">
          <el-input v-model="passWordForm.confirmNewPassword" type="password" autocomplete="off">
          </el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import ErrorBox from "../components/ErrorBox";
import { getUserInfo, setSelfInfo, changePassword } from "../api/user";
export default {
  name: "login",
  data() {
    return {
      passWordForm: {},
      dialogFormVisible: false,
      msg: null,
      icon: null,
      PassErr: false,
      errorIcon: "error-white",
      tickIcon: "tick-white",
      userInfo: {},
      formLabelWidth: '100px'
    };
  },
  components: {
    ErrorBox,
  },
  created() {
    this.GetUserInfo();
  },
  methods: {
    async GetUserInfo() {
      const res = await getUserInfo();
      this.userInfo = res.data.data.userInfo;
      // localStorage.setItem("userInfo", JSON.stringify(res.data.data.userInfo));
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async handleSubmit() {
      // Check if the new password and confirmation match
      if (this.passWordForm.newPassword !== this.passWordForm.confirmNewPassword) {
        this.$vToastify.error("两次密码输入的不一致 请检查！", "Error");
        return;
      } else {
        this.PassErr = false;
        const data = {
          password: this.passWordForm.oldPassword,
          newPassword: this.passWordForm.newPassword,
        };
        const res = await changePassword(data);
        if (res.data.code == 0) {
          this.$vToastify.setSettings({
            theme: "beast-success",
          });
          this.$vToastify.success("密码修改成功！", "Success");
          await this.sleep(1500);
          this.dialogFormVisible = false
          this.passWordForm = {}
        } else {
          this.$vToastify.setSettings({
            theme: "beast-error",
          });
          this.$vToastify.error(res.data.msg, "Error");
        }

      }
    },
    async reset() {
      const res = await setSelfInfo(this.userInfo);
      if (res.data.code == 0) {
        this.$vToastify.setSettings({
          theme: "beast-success",
        });
        this.$vToastify.success("修改成功", "Success");
        await this.sleep(1500);
      }
    },
  },
  beforeCreate() {
    this.$store.commit("updateCurrentPage", "ResetPassword");
  },
};
</script>

<style>
.button-class {
  display: flex;
  justify-content: start;
  /* Aligns buttons to the left */
  align-items: center;
  gap: 5px;
  /* Reduced gap between the buttons */
}
</style>