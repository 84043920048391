<template>
  <div class="challenge-main-container" v-if="renderParam === '1'">
    <div class="adminChallengesVerticalNav tags">
      <a v-for="(tag, index) in tags" v-bind:key="index"
        :class="{ navButton: true, active: selectedTag.name === tag.name }" :tag="tag.name"
        @click="changeFilter(tag)">{{ tag.name }}
      </a>
    </div>
    <div class="challenge">
      <div class="chall-details">
        <StatsNavbar :details="userDetails" :total="totalChals" />
        <div class="chall">
          <ChallengesByTag :tag="this.selectedTag.name" :challenges="this.displayChallenges"
            :selectDefaultChallenge="selectDefaultChallenge" @clicked="selectChallenge" />
          <ChallCard :challDetails="selectedChall" @updateChallenges="() => {
      fetchChallenges(true);
      updateUserStats();
    }
    " />
        </div>
      </div>
    </div>
  </div>
  <beforeChallStart v-else-if="renderParam === '0'" />
  <auth v-else-if="renderParam === '3'" />
  <Restricted v-else-if="renderParam === '4'" />
  <afterChallOver v-else />
</template>

<script>
import StatsNavbar from "@/components/Stats.vue";
import ChallengesByTag from "@/components/ChallengesByTag.vue";
import beforeChallStart from "@/components/beforeChallStart.vue";
import afterChallOver from "@/components/afterChallOver.vue";
import auth from "@/components/auth.vue";
import Restricted from "@/components/Restricted.vue"
import ChallCard from "@/components/ChallCard.vue";
import LoginUser from "../api/admin/authAPI.js";
import { getChallenges } from "../utils/challenges";
import ChalService from "@/api/admin/challengesAPI";
// import { GetEnv } from "../api/docker";
import { notifPollingInterval } from "@/constants/constants";
export default {
  name: "Challenges",
  data() {
    return {
      pollingId: null,
      tag: "All",
      challenges: [],
      displayChallenges: [],
      defaultTags: [{ name: "All", id: 1 }],
      selectedTag: { name: "All", id: 1 },
      challengesNotFetched: true,
      usersNotFetched: true,
      //用户信息展示
      userDetails: {
        score: 0,
        solves: [],
      },
      totalChals: 0, //题目总数
      selectedChall: null,
      selectDefaultChallenge: true,
      renderParam: "1",
      tags: [],
    };
  },
  components: {
    StatsNavbar,
    ChallengesByTag,
    ChallCard,
    afterChallOver,
    auth,
    beforeChallStart,
    Restricted
  },

  async mounted() {
    await this.pollNotifications();
    await this.fetchChallenges(false);
    await this.updateUserStats();
    localStorage.setItem("uuid", this.$route.params.uuid);
  },
  beforeDestroy() {
    clearInterval(this.pollingId);
  },
  created() {
    this.username = LoginUser.getUserInfo().userName;
  },
  methods: {
    async pollNotifications() {
      await this.fetchChallenges(false);
      // await this.updateUserStats();
      this.pollingId = setInterval(async () => {
        await this.fetchChallenges(false);
        // await this.updateUserStats();
      }, notifPollingInterval);
    },
    //获取用户答题页面信息
    async updateUserStats() {
      const res = await ChalService.GetUserRank(this.$route.params.uuid);
      this.userDetails = res.data.data.data;
      this.usersNotFetched = false;
    },
    async fetchChallenges(isUpdation) {
      const uuid = this.$route.params.uuid;
      const challData = await getChallenges(true, uuid);
      if (challData.error) {
        this.renderParam = challData.message;
        return;
      }
      this.challenges = challData.challenges;
      this.tags = [...this.defaultTags, ...challData.tagFilterOptions];
      this.totalChals = challData.challenges.length;
      this.displayChallenges = challData.displayChallenges;
      if (!isUpdation) {
        var i = 0;
        while (i < this.displayChallenges.length) {
          if (this.displayChallenges[i].status == "Deployed") {
            this.selectedChall = this.displayChallenges[i];
            break;
          }
          i++;
        }
      }
      this.challengesNotFetched = false;
      this.selectDefaultChallenge = false;
    },
    changeFilter(value) {
      this.selectedTag = value;
      if (value.name === "All") {
        this.displayChallenges = this.challenges;
      } else {
        this.displayChallenges = this.challenges.filter((challenge) => {
          let includeChallenge = false;
          challenge.tags.forEach((tag) => {
            if (tag === value.name) {
              includeChallenge = true;
            }
          });
          return includeChallenge;
        });
      }
    },
    selectChallenge(name) {
      if (name === null) {
        this.selectedChall = null;
      }
      // this.selectedChall.portsmap = "";
      this.selectedChall = this.challenges.filter((el) => {
        return el.name == name;
      })[0];
    },
  },
  beforeCreate() {
    this.$store.commit("updateCurrentPage", "userChallenges");
  },
};
</script>
