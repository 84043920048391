import Vue from "vue";
import VueRouter from "vue-router";
import Challenges from "@/views/Challenges.vue";
import Leaderboard from "@/views/Leaderboard.vue";
import Notification from "@/views/Notifications.vue";
import Login from "@/views/Login.vue";
import ErrorPage from "@/views/ErrorPage.vue";
// import Articles from "@/views/Articles.vue"
// import Tools from "@/views/Tools.vue";
// import Post from "@/views/Post.vue";
import wpUpload from "@/views/wpupload.vue";
import License from "@/views/License.vue";
import Index from "@/views/Index.vue";
import Register from "@/views/Register.vue";
import Reset from "../views/Reset.vue";
import LoginUser from "../api/admin/authAPI.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
    meta: {
      layout: "auth-layout"
    }
  },
  // {
  //   path: "/markdown",
  //   name: "markdown",
  //   component: Articles,
  //   meta: {
  //     layout: "auth-layout"
  //   }
  // },
  // {
  //   path: "/tools",
  //   name: "tools",
  //   component: Tools,
  //   meta: {
  //     layout: "auth-layout"
  //   }
  // },
  {
    path: "/license",
    name: "license",
    component: License,
    meta: {
      layout: "auth-layout"
    }
  },
  {
    path: "/challenges/:uuid",
    name: "challenges",
    component: Challenges,
    meta: {
      layout: "user-layout"
    }
  },
  // {
  //   path: "/post/:id",
  //   name: "post",
  //   component: Post,
  //   meta: {
  //     layout: "auth-layout"
  //   }
  // },
  {
    path: "/leaderboard",
    name: "leaderboard",
    component: Leaderboard,
    meta: {
      layout: "user-layout"
    }
  },
  {
    path: "/notifications",
    name: "notifications",
    component: Notification,
    meta: {
      layout: "user-layout"
    }
  },
  {
    path: "/wpupload",
    name: "wpUpload",
    component: wpUpload,
    meta: {
      layout: "user-layout"
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      layout: "auth-layout"
    },
    beforeEnter: function (to, from, next) {
      let userInfo = LoginUser.getUserInfo();
      if (userInfo) {
        if (userInfo.role === "admin") {
          router.push("/admin/");
        } else {
          router.push("/about/");
        }
      } else {
        next();
      }
    }
  },
  {
    path: "/resetpassword",
    name: "resetpassword",
    component: Reset,
    meta: {
      layout: "auth-layout"
    }
  },

  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      layout: "auth-layout"
    }
  },
  {
    path: "/error/500",
    name: "500",
    component: ErrorPage,
    props: {
      errorCode: "500"
    },
    meta: {
      layout: "error-layout"
    }
  },
  {
    path: "/error/networkerror",
    name: "NetworkError",
    component: ErrorPage,
    props: {
      errorCode: "500"
    },
    meta: {
      layout: "error-layout"
    }
  },
  {
    path: "/error/401",
    name: "401",
    component: ErrorPage,
    props: {
      errorCode: "401"
    },
    meta: {
      layout: "error-layout"
    }
  },
  {
    path: "*",
    name: "404",
    component: ErrorPage,
    props: {
      errorCode: "404"
    },
    meta: {
      layout: "error-layout"
    }
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  let pagePath = to.path;
  let userPages = ["/challenges", "/leaderboard", "/notifications", "/about"];
  let userInfo = LoginUser.getUserInfo();
  if (
    userPages.includes(pagePath) &&
    userInfo &&
    userInfo.role !== "user"
  ) {
    next({
      path: "/error/401"
    });

  } else if (userInfo && to.path === '/login') {
    next('/')
  }
  else {
    next();
  }
});

export default router;
