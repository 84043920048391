<template>
  <spin-loader v-if="isLoading" />
  <div class="pre-chall-container" v-else>
    <img class="hourglass-img" src="@/assets/hourglass.png" alt="hourglass" />
    <div class="pre-message">
      <p class="pre-message-text">比赛开始倒计时</p>
    </div>
    <div class="timer-sec" v-if="days">
      <div class="timer2-text">{{ days }}Day(s)</div>
      <div class="timer2-text">{{ this.formatValue(hours) }}:</div>
      <div class="timer2-text">{{ this.formatValue(minutes) }}:</div>
      <div class="timer2-text seconds-timezoneimer">
        {{ this.formatValue(seconds) }}
      </div>
    </div>
    <div class="timer-sec" v-else>
      <div class="timer2-text">{{ this.formatValue(hours) }}:</div>
      <div class="timer2-text">{{ this.formatValue(minutes) }}:</div>
      <div class="timer2-text seconds-timer">
        {{ this.formatValue(seconds) }}
      </div>
    </div>
  </div>
</template>
  
<script>
import moment from "moment-timezone";
import SpinLoader from "../components/spinLoader.vue";
export default {
  components: { SpinLoader },
  name: "timer",
  data: function () {
    return {
      start: "",
      end: "",
      interval: "",
      days: "",
      minutes: "",
      hours: "",
      seconds: "",
      statusType: "",
      startTime: "", // 修改为东八区时间
      endTime: "", // 修改为东八区时间
      isLoading: false
    };
  },
  mounted() {
    this.startTime = localStorage.getItem("start_time")
    this.endTime = localStorage.getItem("end_time")
    
    this.start = moment.tz(this.startTime, "Asia/Shanghai");
    this.end = moment.tz(this.endTime, "Asia/Shanghai");
    // Update the count down every 1 second
    this.timerCount(this.start, this.end);
    this.interval = setInterval(() => {
      this.timerCount(this.start, this.end);
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    formatValue(timeElement) {
      if (timeElement < 10) {
        return "0" + timeElement.toString();
      }
      return timeElement;
    },
    timerCount: function (start, end) {
      // Get todays date and time
      let now = moment.tz("Asia/Shanghai");

      // Find the distance between now an the count down date
      let distance = start.diff(now);
      let passTime = end.diff(now);

      if (distance < 0 && passTime < 0) {
        this.isLoading = true;
        this.$router.go();
        this.statusType = "Expired";
        clearInterval(this.interval);
        return;
      } else if (distance < 0 && passTime > 0) {
        this.isLoading = true;
        this.$router.go();
        this.statusType = "running";
        this.calcTime(passTime);
      } else if (distance > 0 && passTime > 0) {
        this.calcTime(distance);
        this.statusType = "Upcoming";
      }
    },
    calcTime: function (dist) {
      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(dist / (1000 * 60 * 60 * 24));
      this.hours = Math.floor(
        (dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((dist % (1000 * 60)) / 1000);
    }
  }
};
</script>