import ChalService from "@/api/admin/challengesAPI";
// import UserService from "@/api/admin/usersAPI";


export const getChallenges = async (getUserSolves, uuid) => {
  const res = await ChalService.fetchAllChallenges(uuid)
  let challenges = []
  let allTags = [];
  let tagFilterOptions = [];
  let displayChallenges = [];
  if (res.data.code == 0) {
    // console.log(res.data.data.rectf)
    localStorage.setItem("statusType", 'running')
    localStorage.setItem("start_time", res.data.data.rectf.start_time)
    localStorage.setItem("end_time", res.data.data.rectf.end_time)

    challenges = res.data.data.challenges

    if (getUserSolves) {
      const res1 = await ChalService.GetUserRank(uuid)

      const solveids = res1.data.data.data.solveids

      challenges.forEach(challenge => {
        if (solveids == null) {
          challenge.isSolved
        } else {
          solveids.forEach(item => {
            if (challenge.ID == item) {

              challenge.isSolved = true;

            }
          })
        }
      });
    }

    challenges.forEach(challenge => {
      challenge.tags.forEach(tag => {
        allTags.push(tag);
      });
    });

    // removing all duplicate tags
    allTags = allTags.filter((item, pos) => {
      return allTags.indexOf(item) == pos;
    });

    let id = 1;
    allTags.forEach(el => {
      tagFilterOptions.push({ id: id, name: el });
      id++;
    });
    displayChallenges = challenges.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });

  } else {
    //res.data.msg
    //判断比赛是否开始
    if (res.data.msg == "0") {
      localStorage.setItem("start_time", res.data.data.start_time)
      localStorage.setItem("end_time", res.data.data.end_time)
      localStorage.setItem("statusType", 'Upcoming')
      return { message: '0', error: true };
    } else if (res.data.msg == "3") {
      localStorage.setItem("statusType", 'Expired')
      return { message: '3', error: true };
    }
    else if (res.data.msg == "4") {
      localStorage.setItem("statusType", 'Expired')
      return { message: '4', error: true };
    } else {
      localStorage.setItem("statusType", 'Expired')
      // console.log(res.data.msg)
      return { message: '2', error: true };

    }
  }

  return {
    challenges,
    allTags,
    tagFilterOptions,
    displayChallenges,
    error: false
  };
};

export const getChalStats = async () => {
  const response = await ChalService.fetchAllChallenges();
  if (response.status !== 200) {
    return null;
  } else {
    let deployedChal = 0;
    let undeployedChal = 0;
    let purgedChal = 0;
    let maxSolves = 0;
    let leastSolves = Number.MAX_SAFE_INTEGER;
    let leastSolvedChal = { name: "-", solves: -1 };
    let maxSolvedChal = { name: "-", solves: -1 };
    response.data.forEach(el => {
      switch (el.status) {
        case "Deployed":
          deployedChal++;
          break;
        case "Undeployed":
          undeployedChal++;
          break;
        case "Purged":
          purgedChal++;
      }
      if (el.solvesNumber <= leastSolves) {
        leastSolves = el.solvesNumber;
        leastSolvedChal = {
          name: el.name,
          solves: leastSolves
        };
      }
      if (el.solvesNumber >= maxSolves) {
        maxSolves = el.solvesNumber;
        maxSolvedChal = {
          name: el.name,
          solves: maxSolves
        };
      }
    });
    return {
      deployedChal,
      undeployedChal,
      purgedChal,
      leastSolvedChal,
      maxSolvedChal
    };
  }
};

export const getChalCategory = async tags => {
  let submissionsCategory = {};
  const response = await ChalService.fetchAllChallenges();
  tags.forEach(tag => {
    let challenges = response.data.filter(challenge => {
      let includeChallenge = false;
      challenge.tags.forEach(t => {
        if (t === tag.name) {
          includeChallenge = true;
        }
      });
      return includeChallenge;
    });
    let totalChallenges = challenges.length;
    let userSolves = [];
    challenges.forEach(challenge => {
      challenge.solves.forEach(solve => {
        if (
          userSolves.findIndex(userSolve => {
            return userSolve.username === solve.username;
          }) === -1
        ) {
          userSolves.push({
            username: solve.username,
            solves: 1
          });
        } else {
          userSolves[
            userSolves.findIndex(userSolve => {
              return userSolve.username === solve.username;
            })
          ].solves++;
        }
      });
    });
    userSolves = userSolves.sort((a, b) => {
      return a.solves < b.solves ? 1 : -1;
    });
    submissionsCategory[tag.name] = {
      solves: userSolves,
      total: totalChallenges
    };
  });
  return submissionsCategory;
};

export const getChalTags = tags => {
  let challTags = "";
  for (let index = 0; index < tags.length; index++) {
    if (index === tags.length - 1) {
      challTags += tags[index];
    } else {
      challTags += tags[index] + " , ";
    }
  }
  return challTags;
};

export const getTags = async () => {
  const resp = await ChalService.fetchAllTags();
  let tags = resp.data.tags;
  let tagArray = [];
  let id = 0;
  tags.forEach(tag => {
    tagArray.push({ id, name: tag });
    id++;
  });
  return tagArray;
};
