<template>
  <div class="pre-chall-container">
    <!-- <img class="hourglass-img" src="@/assets/team-goals.png" alt="teamgoals" /> -->
    <div class="pre-message">
      <p class="pre-message-text">
        您已经被禁赛 请联系管理人员！
      </p>
    </div>
    <router-link style="text-decoration: none" to="/">
      <Button text="返回" class="primary-cta" :onclick="test" /></router-link>
  </div>
</template>

<script>
import Button from "./Button.vue";
export default {
  name: "rest",
  components: {
    Button
  },
  beforeCreate() {
    this.$store.commit("updateCurrentPage", "userChallenges");
  },
  methods: {
    test() {

    }
  }
};
</script>
