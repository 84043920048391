<template>
  <div id="default-layout" :key="componentKey">
    <Navbar />
    <div id="container">
      <slot />
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
// import Footer from "../components/Footer.vue";
export default {
  name: "DefaultLayout",
  components: {
    Navbar,
    // Footer
  },
  props: {
    uuid: String
  },
  data() {
    return {
      componentKey: 0 // 用于强制重新渲染子组件
    }
  },
};
</script>
