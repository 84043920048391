<template>
    <div class="auth" v-if="MachineInfo.status">
        <div class="auth-container">
            <div class="heading">授权绑定<el-tag type="success">已绑定</el-tag></div>

            <ErrorBox v-if="msg" :msg="msg" :icon="icon" />
        </div>
        <div class="auth-subheading"></div>
        <div class="form-div">
            <div class="form">
                <div class="info">
                    <label for="version" class="input-label">版本：</label>
                    <input v-model="MachineInfo.version" type="text" class="adminFormTitle inputField" id="version"
                        name="version" placeholder="版本" required="true" disabled />
                </div>
                <div class="info">
                    <label for="machineCode" class="input-label">机器码：</label>
                    <input v-model="MachineInfo.machineCode" type="text" class="adminFormTitle inputField"
                        id="machineCode" name="machineCode" placeholder="机器码" required="true" disabled />
                </div>
                <div class="info">
                    <label for="licenseCode" class="input-label">授权码：</label>
                    <input v-model="MachineInfo.licenseCode" type="text" class="adminFormTitle inputField"
                        id="licenseCode" name="licenseCode" placeholder="授权码" required="true" disabled />
                </div>

                <div class="button-class">
                    <el-button type="warning" @click="downloadLicense">下载授权</el-button>
                </div>
            </div>
            <img src="@/assets/login.svg" class="auth-image" />
        </div>

    </div>
    <div class="auth" v-else>
        <div class="auth-container">
            <div class="heading">授权绑定<el-tag type="info">未绑定</el-tag></div>
            <ErrorBox v-if="msg" :msg="msg" :icon="icon" />
        </div>
        <div class="auth-subheading"></div>
        <div class="form-div">
            <div class="form">
                <div class="info">
                    <input v-model="MachineInfo.MachineCode" type="text" class="adminFormTitle inputField" id="text"
                        name="text" placeholder="机器码" required="true" />
                </div>

                <div class="button-class">
                    <el-button type="primary" @click="bangding">确定</el-button>
                </div>
            </div>
            <img src="@/assets/login.svg" class="auth-image" />
        </div>
    </div>
</template>

<script>
import ErrorBox from "../components/ErrorBox";
import { getLicense, bdGLicense } from "../api/license"
export default {
    name: "login",
    data() {
        return {
            text: "",
            MachineInfo: {},
            msg: null,
            icon: null,
            PassErr: false,
            errorIcon: "error-white",
            tickIcon: "tick-white",
            formLabelWidth: '100px'
        };
    },
    components: {
        ErrorBox,
    },
    created() {
        this.GetLicenseStatus();

    },
    methods: {
        async GetLicenseStatus() {
            const res = await getLicense();
            if (res.data.code === 0) {
                this.MachineInfo = res.data.data.regl

            } else {

                // this.MachineInfo.MachineCode = ""
                this.MachineInfo.status = false
            }

        },
        async bangding() {
            const res = await bdGLicense(this.MachineInfo)
            if (res.data.code == 0) {
                this.$vToastify.setSettings({
                    theme: "beast-success",
                });
                this.$vToastify.success("绑定成功", "Success");
                this.GetLicenseStatus();
            }else{
                this.$vToastify.error("绑定失败 请检查", "Error");
            }
        },
        async downloadLicense() {
            const res = await getLicense();
            if (res.data.code === 0) {
                // 将JSON对象转换为字符串
                const jsonString = JSON.stringify(res.data.data.regl);

                // 创建一个新的Blob对象
                const blob = new Blob([jsonString], { type: 'application/json' });

                // 创建一个URL对象
                const url = URL.createObjectURL(blob);

                // 创建一个链接元素
                const link = document.createElement('a');
                link.href = url;

                // 设置下载属性为文件名
                link.download = res.data.data.regl.userUuid + '.lic';

                // 将链接元素添加到文档中
                document.body.appendChild(link);

                // 触发点击事件以开始下载
                link.click();

                // 释放URL对象
                URL.revokeObjectURL(url);

                // 移除链接元素
                document.body.removeChild(link);

            } else {
                // this.MachineInfo.MachineCode = ""
                this.$vToastify.error("下载失败", "Error")
            }

        }
    },
    beforeCreate() {
        // this.$store.commit("updateCurrentPage", "ResetPassword");
    },
};
</script>

<style>
.button-class {
    display: flex;
    justify-content: start;
    /* Aligns buttons to the left */
    align-items: center;
    gap: 5px;
    /* Reduced gap between the buttons */
}
</style>