<template>
  <div class="navbar">
    <div class="user-navbar-container" v-if="loggedIn()">
      <router-link to="/" class="navbar-link ctfLogo">
        <div class="logo">
          <img v-if="this.getLogoUrl()" src="../assets/jiangbei.svg" />
        </div>
      </router-link>
      <nav class="navbar-container">
        <div class="link-container">
          <router-link
            v-for="routes in getLinks()"
            v-bind:key="routes.index"
            :class="{
              'navbar-link': true,
            }"
            :to="`${routes.page}/`"
            >{{ routes.text }}
            <el-badge
              v-if="routes.text === '通知' && ndata && ndata.length > 0"
              :value="ndata.length"
              class="item"
            ></el-badge>
          </router-link>
          <div class="adminNavTimer" v-if="statusType == 'running'">
            <p class="adminTimerMessage">剩余时间</p>
            <Timer :uuid="ctf_uuid" class="adminTimerValue" />
          </div>
        </div>
      </nav>
      <!-- <div
        class="adminNavDropdown"
        @mouseover="showDropdownContent(true)"
        @mouseleave="showDropdownContent(false)"
      >
        <button class="adminNavDropbtn">
          {{ username }}
          <img :src="dropdown" />
        </button>
        <div v-if="displayDropdownContent" class="adminNavDropdownContent">
          <router-link to="/license" class="adminNavDropdownLink"
            >GTools绑定</router-link
          >
          <router-link to="/resetpassword" class="adminNavDropdownLink"
            >个人信息</router-link
          >
          <a class="adminNavDropdownLink cursor-pointer" @click="logout()">退出</a>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Timer from "@/components/Timer.vue";
import { dropdown } from "../constants/images";
// import { CONFIG } from "@/config/config";
import LoginUser from "../api/admin/authAPI.js";
import { getUserInfo } from "../api/user";
import NotificationService from "@/api/admin/notificationsAPI";

export default {
  name: "Navbar",
  data() {
    return {
      ndata: [],
      ctf_uuid: "",
      dropdown,
      username: "",
      displayDropdownContent: false,
      baseUrl: "",
      userRole: LoginUser.getUserInfo().role,
      statusType: "",
      userLinks: [
        // {
        //   index: 0,
        //   text: "题目",
        //   page: "/challenges/",
        // },
        {
          index: 1,
          text: "排行榜",
          page: "/leaderboard",
        },
        {
          index: 2,
          text: "通知",
          page: "/notifications",
        },
        {
          index: 3,
          text: "WP上传",
          page: "/wpupload",
        }
      ],
      userProfilePath: "/user/",
    };
  },
  components: {
    Timer,
  },

  created() {
    this.GetUserInfoAndCTFUUID();
    this.timer = setInterval(() => {
      this.GetNotificationData();
    }, 30000); // 设置轮询间隔时间，单位为毫秒
  },
  beforeDestroy() {
    clearInterval(this.timer); // 清除定时器
  },
  methods: {
    async GetUserInfoAndCTFUUID() {
      const res = await getUserInfo();
      this.username = res.data.data.userInfo.nickName;
      this.userProfilePath += this.username;
      this.ctf_uuid = localStorage.getItem("uuid");
      this.statusType = localStorage.getItem("statusType");
    },
    getLinks() {
      return this.userLinks;
    },
    loggedIn() {
      return LoginUser.getUserInfo().login;
    },
    logout() {
      LoginUser.logout();
      this.$router.push("/login");
    },
    showDropdownContent(showContent) {
      this.displayDropdownContent = showContent;
    },
    async GetNotificationData() {
      const res = await NotificationService.getAllNotifs(localStorage.getItem("uuid"));
      this.ndata =res.data.data.list
    },
  },
};
</script>
